<template>
  <a-layout class="main-container">
    <banner />
    <div class="process-container">
      <div class="process-title">申请流程</div>
      <div class="process-divider"></div>
      <div class="process-title-en">Application process</div>
      <div class="process-content">
        <p>第一步：搜索“中国少年儿童造型艺术学会”公众号，关注公众号。</p>
        <p>
          第二步：准备填写申请表的材料，需要准备好一份电子版一寸照（填写时需要上传），整理好个人简历（需要填写）。
        </p>
        <p>
          第三步：打开学会公众号菜单“加入学会”→“入会申请”，在系统发送的信息中点击填写链接，跳转到申请表填写页面，请认真按照表单中要求的内容进行填写。邮寄地址、发票信息部分请务必填写仔细，确认信息无误。提交申请表后，耐心等待7-14个工作日，审核结果将通过邮箱、短信或电话的形式发送，请注意查收。
        </p>
        <p>
          第四步：收到信息后，点击会费缴纳的链接，进行会费的缴纳，注意填写真名备注，以便确认缴纳人信息做归档。
        </p>
        <p>
          第五步：会费缴纳成功后，将会有实体的会员证通过快递送达，请注意查收。一次性缴费会员有效期为一年，到期未续费将自动取消会员身份。
        </p>
        <div class="tip">
          <p>注：1.会员申请仅对成年人开放，暂不接受未成年人与集体单位申请。</p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.入会咨询，请联系熊老师：199
            1063 8118（微信同号）
          </p>
        </div>
        <img src="@/assets/member/icon-arrow.png" alt="" class="img-arrow" />
      </div>
    </div>
  </a-layout>
</template>

<script>
import Banner from "@/views/member/components/Banner.vue";

export default {
  components: {
    Banner
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="less">
.main-container {
  min-height: 100vh;
  background-color: #f1f2f5;
  padding-top: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .process-container {
    width: 1150px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 78px;
    position: relative;
    .process-title {
      font-weight: 600;
      font-size: 30px;
      color: #000000;
      margin-top: 52px;
    }
    .process-divider {
      width: 231px;
      height: 3px;
      background-color: #000000;
      margin: 4px 0;
    }
    .process-title-en {
      font-weight: 600;
      font-size: 20px;
      color: #1ddd7e;
    }
    .process-content {
      width: 746px;
      margin-top: 36px;
      p {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        line-height: 36px;
        text-align: justify;
        margin-bottom: 0;
      }
      .tip {
        margin-top: 8px;
        margin-bottom: 33px;
        p {
          font-weight: 600;
          font-size: 16px;
          color: #000000;
          line-height: 36px;
          text-align: justify;
        }
      }
    }
    .img-arrow {
      width: 80px;
      height: 80px;
      position: absolute;
      top: 53px;
      left: 162px;
    }
  }
}
</style>
